
import { Fade } from "react-awesome-reveal";
import Slider from "react-slick";
import React, { useRef } from "react";
import "../styles/style.css";
const SectionOne: React.FC<any> = ({ scrollToTheForm, redirectToShopify, data }) => {
  const slider = useRef(null);

  const settings = {
    autoplay: true,
    autoplaySpeed: 5000,
    fade: false,
    slide: "div",
    cssEase: "linear",
    dots: false,
    arrows: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 0,
    rtl: false,
  };

  const renderArrows = () => {
    return (
      <div className="right-arrow">
        <img
          src="/assets/images/kwik-checkout-cod/forward_icon_cod.svg" alt=""
          onClick={() => slider.current.slickNext()}
        />
      </div>
    );
  };


  return (
    <div className="kwik-checkout-cod">
      <div className="container-fluid">

        <div className="row">
          <div className="col-sm-12 col-md-7">
            <div className="checkcod">
              {/* <h1 className="b-heading">
                {data.page?.banner?.title}
                Reduce RTO with smart COD suite on Shopify App
              </h1> */}
              <h1 className="b-heading" dangerouslySetInnerHTML={{ __html: data.page?.banner?.title }}></h1>
              <p className="t-head">
                {data.page?.banner?.sub_title}
                {/* #1 Alternative to Advanced COD Shopify App */}
              </p>
              <div className="home-p" dangerouslySetInnerHTML={{ __html: data.page?.banner?.description }} >
                {/* <ul>
                  <div className='list1'>
                    <li>Hide/Offer COD</li>
                    <li>Verify COD intent</li>
                  </div>
                  <div className='list2'>
                    <li>Enable shipping fees</li>
                    <li>Enable discount features</li>
                  </div>
                </ul> */}
              </div>
              <div className="banner-offer-button-container text-center">
                {/* <button className="top-button" onClick={scrollToTheForm}>
                    Book a Free Demo
                  </button> */}
                <button className="top-button" onClick={() => redirectToShopify(1)}>
                  Install the {'  '}<img src="/assets/images/kwik-checkout-cod/shopify.png" alt="" width={20} /> {'  '} Shopify App
                </button>
              </div>
              <div className="cod-banner-team">
                <Slider {...settings} ref={slider}>
                  {
                    data?.blocks?.block1 && data?.blocks?.block1.map((block) => {
                      return (
                        <div className="">
                          <div className="cod-banner-info">
                            <img
                              src="/assets/images/kwik-checkout-cod/double_coma_icon.png"
                              width={50}
                            />

                            <p>
                              <h2>{block.title}</h2>
                              {block.content}
                            </p>
                          </div>
                          <div className="cod-bimg">
                            <img
                              src={block.file}
                              alt="rune-logo-img"
                            />
                          </div>
                        </div>
                      )
                    })
                  }
                  {/* <div className="">
                    <div className="cod-banner-info">
                      <img
                        src="/assets/images/kwik-checkout-cod/double_coma_icon.png"
                        width={50}
                      />

                      <p>
                        <h2>Great Alternative</h2>
                        It'a a great alternative for Shopify native COD App with best options
                      </p>
                    </div>
                    <div className="cod-bimg">
                      <img
                        src="/assets/images/kwik-checkout-cod/zestpics.png"
                        alt="rune-logo-img"
                      />
                    </div>
                  </div>
                  <div className="">
                    <div className="cod-banner-info" style={{ alignItems: 'center' }}>
                      <img
                        src="/assets/images/kwik-checkout-cod/double_coma_icon.png"
                        width={50}
                      />
                      <p>
                        <h2>Strong recommend </h2>
                        Very nice App and very supportive staff. I strong recommend Gokwik COD app to all the D2C Brands.
                      </p>
                    </div>
                    <div className="cod-bimg">
                      <img
                        src="/assets/images/kwik-checkout-cod/bhoj.png"
                        alt="rune-logo-img"
                      />
                    </div>
                  </div>
                  <div className="">
                    <div className="cod-banner-info">
                      <img
                        src="/assets/images/kwik-checkout-cod/double_coma_icon.png"
                        width={50}
                      />
                      <p>
                        <h2>5 Star Rating By Merchants</h2>
                        Amazing Experience with GoKwik , the onboarding is seamless and the merchant dashboard is also very detailed.
                      </p>
                    </div>
                    <div className="cod-bimg">
                      <img
                        src="/assets/images/kwik-checkout-cod/roopwali.png"
                        alt="rune-logo-img"
                      />
                    </div>
                  </div>
                  <div className="">
                    <div className="cod-banner-info">
                      <img
                        src="/assets/images/kwik-checkout-cod/double_coma_icon.png"
                        width={50}
                      />
                      <p>
                        <h2>Best Alternate app</h2>
                        We are in the testing phase with the Gokwik app and are already seeing promising results. The team was very responsive to our questions and quick to offer solutions.
                      </p>
                    </div>
                    <div className="cod-bimg">
                      <img
                        src="/assets/images/kwik-checkout-cod/gynoveda.png"
                        alt="rune-logo-img"
                      />
                    </div>
                  </div> */}
                </Slider>

                {renderArrows()}
              </div>
            </div>
          </div>

          <div className="col-sm-12 col-md-5 text-center pointer" onClick={redirectToShopify}>
            {/* <img src="/assets/images/kwik-checkout-cod/review_board.svg"
              alt="review board png"
            /> */}
            <img src={data.page?.banner?.file}
              alt="review board png"
            />
          </div>
        </div>
        <div className="lower-container">
          <div className="exclusive-offer-container">
            <div className="row">
              <div className="col-md-8">
                <div className="exclusive-offer-text">
                  <div className="exclusive-offer-onheader">
                    {/* Exclusive Offer For Basic and Shopify Plan */}
                    {
                      data?.blocks?.block2 && data?.blocks?.block2.title
                    }
                  </div>
                  <p className="exclusive-offer-paragraph">
                    {
                      data?.blocks?.block2 && data?.blocks?.block2.content
                    }
                    {/* COD orders completely free for first 3 months */}
                  </p>
                </div>
              </div>
              <div className="col-md-4">
                <div className="exclusive-offer-button-container">
                  {/* <button className="offer-button" onClick={scrollToTheForm}>
                    Book a Free Demo
                  </button> */}
                  <button className="offer-button" onClick={() => redirectToShopify(1)}>
                    Free Install on {'  '}<img src="/assets/images/kwik-checkout-cod/shopify.png" alt="" width={20} /> {'  '} shopify
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SectionOne;
